<template>
	<div>
		<section id="hero">
			<div class="hero-container">
				<div class="hero-content-container">
					<h2 class="hero-title">Anmäl dig till sportens alla aktviter</h2>
					<p class="text-blended">
						Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam corporis dolore dolorum earum
						illo
						laborum saepe tempora tempore voluptate voluptates?
					</p>
				</div>

				<div class="hero-img-container"></div>
			</div>
		</section>

		<main class="py-0">
			<section id="search">
				<BaseSearchInput type="search" v-model="search" placeholder="">
					Sök aktivitet
				</BaseSearchInput>
			</section>

			<section id="activities">

				<div class="activity-card" v-for="activity in activities" :key="activity.id">
					<h4 class="pb-2">{{ activity.title }}</h4>
					<p>{{ trimString(activity.description) }}</p>

					<div class="flex items-center gap-12 my-6">
						<div>
							<h6>Datum</h6>
							<p>{{ formatDateString(activity.starting_at) }}</p>
						</div>
						<div>
							<h6>Sista anmälan</h6>
							<p>{{ formatDateString(activity.last_application_at) }}</p>
						</div>
						<div>
							<h6>Max</h6>
							<p>{{ activity.max_applications }}</p>
						</div>
					</div>

					<div class="flex items-center gap-5 mt-6 mb-10">
						<BaseChip :remove="false">
							{{ activity.operation.title }}
						</BaseChip>
						<BaseChip :remove="false">
							{{ activity.city }}
						</BaseChip>
					</div>

					<BaseLinkButton :to="`/activity/${activity.id}/general`">
						Gå till aktivitet
					</BaseLinkButton>
				</div>
			</section>
		</main>
	</div>
</template>

<script>
import {formatDateString} from '@/core/helpers/date'

export default {
	name: 'Home',
	data: () => ({
		search: '',
	}),
	computed: {
		activities() {
			return this.$store.getters['ActivityStore/activities']
		},
	},
	methods: {
		formatDateString,
		fetchActivities() {
			this.$store.dispatch('ActivityStore/index')
		},
		trimString(str) {
			str = str.substring(0, 100)
			const lastIndex = str.lastIndexOf(' ')
			const result = str.substring(0, lastIndex)
			return str = `${result}...`
		},
	},
	created() {
		this.fetchActivities()
	},
}
</script>

<style scoped>
#hero {
	@apply bg-white p-0;
}

.hero-container {
	@apply bg-white container mx-auto ;
	@apply grid gap-5 grid-cols-12;
}

.hero-content-container {
	@apply col-span-5 pl-5 py-64;
}

.hero-content-container .hero-title {
	@apply pb-4 text-4xl;
	line-height: 3rem;
}

.hero-img-container {
	@apply col-span-7 bg-gray-200 mr-5;
}

#search {
	@apply pb-5;
}

#activities {
	@apply py-5;
	@apply grid grid-cols-3 gap-5;
}

.activity-card {
	@apply bg-white px-6 py-8 shadow rounded;
}
</style>